.polyfill-loader {
  display: none;
}

@supports not (container-type: inline-size) {
  .polyfill-wrapper {
    display: none;
  }

  .polyfill-loader {
    display: flex;
  }
}
